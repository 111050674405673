import type { VisionBoardTheme } from '~/types/visionBoard';
import type { SystemTheme } from '~/types/system';

export const useThemeStore = defineStore('themeStore', () => {
  const theme = ref<VisionBoardTheme | null>(null);

  const { user } = storeToRefs(useAuthStore());

  const visionBoardTheme = computed<VisionBoardTheme>(() => {
    return 'space';

    /**
     * When we have multiple themes i.e. space, beach etc we uncomment this
     *
     * return theme.value;
     */
  });

  const sysTheme = ref<SystemTheme>('light');
  const systemTheme = computed(() => {
    if (visionBoardTheme.value === 'space') {
      return 'dark';
    }
    return sysTheme.value;
  });

  const setPreferredTheme = (newTheme: VisionBoardTheme) => {
    theme.value = newTheme;
    return true;
  };

  const animsEnabled = ref<boolean>(true);

  const animationsEnabled = computed(() => {
    return animsEnabled.value;
  });
  const setAnimationsEnabled = (value: boolean) => {
    animsEnabled.value = value;
  };

  watch(
    user,
    (newUser, oldUser) => {
      if (!oldUser && newUser && !newUser.animationsEnabled) {
        animsEnabled.value = false;
      }
    },
    { immediate: true },
  );

  return { visionBoardTheme, setPreferredTheme, systemTheme, setAnimationsEnabled, animationsEnabled };
});
