import range from 'lodash/range';
import type { NumberOption } from '~/types/selectOption';
import UserActivityError from '~/utils/errors/UserActivityError';
import UnexpectedGraphqlError from '~/utils/errors/UnexpectedGraphqlError';
import { H3Error } from 'h3';

export const useRequestHost = () => {
  return import.meta.server ? useRequestHeaders().host : window.location.host;
};

export const useDomainConfig = () => {
  const config = useRuntimeConfig();
  const siteDomain = useRequestHost().replace(/^www\./, '');

  const isManageDomain = siteDomain.startsWith('manage.');

  const clusterDomain = siteDomain.replace(/^manage\./, '');

  const useHttpForApi = import.meta.server && config.public.useHttpForApiOnServer;
  const useHttp = useHttpForApi || config.public.useHttpForApi;

  return {
    siteDomain,
    clusterDomain,
    apiUrl: `${useHttp ? 'http' : 'https'}://${siteDomain}/api`,
    isManageDomain,
  };
};

export const useUrqlClient = () => {
  return useNuxtApp().$urqlClient;
};

export const capitalizeName = (name: string) => name.replace(/\b(\w)/g, (s) => s.toUpperCase());

export const useClassYears = () => {
  const configStore = useConfigStore();
  const country = configStore.publicConfig?.country;
  if (!country) {
    throw new Error('configStore.publicConfig.country is not defined');
  }

  const { t } = useI18n();

  const calendarYear = new Date().getFullYear();

  const currentYearRollover = new Date(calendarYear, country.rolloverMonth, country.rolloverDay, 23, 59, 59);

  const currentYearHasRolledOver = new Date() > currentYearRollover;
  const currentYear = currentYearHasRolledOver ? calendarYear + 1 : calendarYear;

  const yearGroupLabel = computed(() => t('general.classYear'));

  const getClassYearFromYearGroup = (yearGroup: number) => {
    return currentYear + (12 - yearGroup);
  };

  const getYearGroupFromClassYear = (classYear: number | null | undefined) => {
    if (!classYear) {
      return null;
    }

    return currentYear + (12 - classYear);
  };

  const getYearGroupFromClassYearText = (classYear?: number | null) => {
    return getYearGroupFromClassYear(classYear) || t('general.graduated');
  };

  // classYear can be null, null -> graduated
  const displayYearGroup = (classYear?: number | null) => {
    if (!classYear) {
      return t('general.graduated');
    }

    return `${t('general.classYear')} ${getYearGroupFromClassYear(classYear)}`;
  };

  const currentClassYearOptions = computed<NumberOption[]>(() => {
    return (
      range(getClassYearFromYearGroup(country.firstYearGroup), getClassYearFromYearGroup(country.lastYearGroup) - 1, -1)
        //  remove all the graduate ... as they will be the null valued ones
        .filter((year) => year > getClassYearFromYearGroup(country.displayAsGraduatedStartYearGroup))
        .map((year: number) => ({
          value: year,
          label: displayYearGroup(year),
        }))
    );
  });

  const allClassYearOptions = computed(() => {
    return range(2000, getClassYearFromYearGroup(country.firstYearGroup) + 1)
      .filter((year) => year > getClassYearFromYearGroup(country.displayAsGraduatedStartYearGroup))
      .map((year: number) => ({
        value: year,
        label: displayYearGroup(year),
      }))
      .reverse();
  });

  const classYearNotUndefinedRule = useValidation().notUndefinedRule(t('errors.optionShouldBeSelected', { param: t('formFields.grade.label') }));

  const gradeToClassYear = () => {
    const options: {
      year: number | null;
      grade: 'graduated' | number | null;
    }[] = range(getClassYearFromYearGroup(country.firstYearGroup), getClassYearFromYearGroup(country.lastYearGroup) - 1, -1)
      .filter((year) => year > getClassYearFromYearGroup(country.displayAsGraduatedStartYearGroup))
      .map((year) => ({ year, grade: getYearGroupFromClassYear(year) }));

    options.push({ grade: 'graduated', year: null });
    return options;
  };

  return {
    getYearGroupFromClassYear,
    getYearGroupFromClassYearText,
    currentClassYearOptions,
    allClassYearOptions,
    displayYearGroup,
    // getGroupName,
    yearGroupLabel,
    classYearNotUndefinedRule,
    gradeToClassYear,
  };
};

export const setupErrorHandling = (errorHandler: { logError: (error: Error, additionalContext?: object) => void }) => {
  const { siteDomain } = useDomainConfig();
  const route = useRoute();

  const ssrHeaders = useRequestHeaders(['user-agent']);

  const globalContext = computed(() => {
    return {
      ...(ssrHeaders && ssrHeaders),
      siteDomain,
      path: route.fullPath,
    };
  });

  const logErrorMessage = (message: string, additionalContext: object = {}) => {
    const loggedError = new Error(message);
    loggedError.name = 'LoggedError';
    errorHandler.logError(loggedError, { ...additionalContext, ...globalContext.value });
  };

  const logError = (error: Error, additionalContext: object = {}) => {
    errorHandler.logError(error, { ...additionalContext, ...globalContext.value });
  };

  const nuxtApp = useNuxtApp();

  const handleGlobalHookError = (error: unknown, hook: string) => {
    if (!(error instanceof Error)) {
      const notErrorError = new Error('global hook error was not an Error object');
      errorHandler.logError(notErrorError, { hook, ...globalContext.value });
      return;
    }

    if (error instanceof H3Error) {
      errorHandler.logError(error, { hook, ...globalContext.value, data: error.data });
      return;
    }

    if (error instanceof UserActivityError) {
      errorHandler.logError(error, { hook, ...globalContext.value, userActivityId: error.userActivityId });
      return;
    }

    if (error instanceof UnexpectedGraphqlError) {
      errorHandler.logError(error, { hook, ...globalContext.value, graphqlError: error.graphqlError?.toString() });
      return;
    }

    errorHandler.logError(error, { hook, ...globalContext.value });
  };

  // Global error handling
  nuxtApp.hook('vue:error', (error) => {
    handleGlobalHookError(error, 'vue:error');
  });

  nuxtApp.hook('app:error', (error) => {
    handleGlobalHookError(error, 'app:error');
  });

  return { logErrorMessage, logError };
};

export const useAnalyticsService = () => {
  return useNuxtApp().$analytics;
};

export const useOrdinalNumber = () => {
  const { t } = useI18n();

  const configStore = useConfigStore();
  const country = configStore.publicConfig?.country;
  if (!country) {
    throw new Error('configStore.publicConfig.country is not defined');
  }

  const pluralMapping = (termKey: Intl.LDMLPluralRule) => {
    switch (termKey) {
      case 'one':
        return t('general.numericalOrdinal.one');
      case 'two':
        return t('general.numericalOrdinal.two');
      case 'few':
        return t('general.numericalOrdinal.three');
      case 'zero':
        return;
      default:
        return t('general.numericalOrdinal.other');
    }
  };

  const plurals = new Intl.PluralRules(country.defaultLocale, { type: 'ordinal' });
  const getOrderNumber = (value: number) => `${value}${pluralMapping(plurals.select(value))}`;

  return { getOrderNumber };
};
