import { useFragment } from '~/graphql/generated/fragment-masking';
import type { AuthUserFieldsFragment } from '~/graphql/generated/graphql';
import { ActivityStatus } from '~/graphql/generated/graphql';
import { shallowUserActivityFragment, shallowUserActivityGroupFragment } from '~/graphql/documents/activities';
import { systemConfigFragment } from '~/graphql/documents/fragments/activityConfigData';

const getAutoloadActivityPath = (user: AuthUserFieldsFragment) => {
  const userActivityGroups = user.userActivityGroups.map((g) => useFragment(shallowUserActivityGroupFragment, g));

  const incompleteGroup = userActivityGroups.find((uag) => uag.status !== 'complete');

  if (!incompleteGroup) {
    return null;
  }

  const incompleteGroupUserActivities = incompleteGroup?.userActivities.map((ua) => {
    const _userActivity = useFragment(shallowUserActivityFragment, ua);
    const systemConfig = useFragment(systemConfigFragment, _userActivity.activity?.config.systemConfig);

    return {
      ..._userActivity,
      activity: {
        ..._userActivity.activity,
        config: {
          systemConfig,
        },
      },
    };
  });

  const autoloadActivity = incompleteGroupUserActivities.find((ua) => {
    return ua.activity?.config.systemConfig?.autoload.isAutoload && ua.status !== ActivityStatus.Complete;
  });

  return autoloadActivity?.activity?.config.systemConfig?.autoload.servingUrlPath;
};

export default defineNuxtRouteMiddleware(async (to) => {
  const siteType = getSiteType(to);
  if (siteType === 'manage') {
    return;
  }
  const authStore = useAuthStore();
  if (!authStore.user) {
    return;
  }

  if (authStore.user.isGhostStudent) {
    return;
  }

  const autoloadActivityPath = getAutoloadActivityPath(authStore.user);
  if (!autoloadActivityPath) {
    return;
  }
  if (to.path !== autoloadActivityPath) {
    return await navigateTo(autoloadActivityPath);
  }
});
