import type { VerifyLoginCodeInput, RequestLoginCodeInput, VerifyStudentInviteOtpCodeInput } from '~/graphql/generated/graphql';
import { RoleContextType } from '~/graphql/generated/graphql';
import {
  verifyLoginCodeMutation,
  logoutMutation,
  requestLoginCodeMutation,
  studentRoleFragment,
  teacherRoleFragment,
  deleteSelfMutation,
  requestStudentInviteOtpCodeMutation,
  verifyStudentInviteOtpCodeMutation,
} from '~/graphql/documents/core';
import { useFragment } from '~/graphql/generated/fragment-masking';

export const useAuth = () => {
  const authStore = useAuthStore();

  const { currentSchoolId } = storeToRefs(authStore);
  const { justLoggedIn } = storeToRefs(useUiStore());

  const { $urqlClient, $i18n, $analytics } = useNuxtApp();

  const { isManageDomain } = useDomainConfig();

  const user = computed(() => authStore.user);

  const isLoggedIn = computed(() => {
    return !!user.value;
  });

  const requestLoginCode = async (data: RequestLoginCodeInput) => {
    authStore.reset();
    return await $urqlClient.mutation(requestLoginCodeMutation, { input: data });
  };

  const triggerPostLoginRedirect = async () => {
    justLoggedIn.value = true;
    await navigateTo({ path: '/dashboard' });
  };

  const completeLoginProcess = async (redirect = true) => {
    await authStore.fetchUser(true);

    $analytics.login(user.value?.id);

    if (redirect) {
      await triggerPostLoginRedirect();
    }
  };

  const verifyLoginCode = async (verifyLoginCodeData: VerifyLoginCodeInput) => {
    const res = await $urqlClient.mutation(verifyLoginCodeMutation, { input: verifyLoginCodeData });

    if (res.error) {
      return { flow: null, error: res.error };
    }

    if (!res.data?.core.auth.verifyLoginCode?.flow) {
      return { flow: null, error: 'An unknown error occurred' };
    }

    return { flow: res.data.core.auth.verifyLoginCode.flow, error: null };
  };

  const requestInviteCode = async (inviteCode: string) => {
    authStore.reset();
    const { error } = await $urqlClient.mutation(requestStudentInviteOtpCodeMutation, { input: { code: inviteCode } });

    if (error) {
      return { error: getFirstErrorMessageFromCombinedErrors($i18n.t, error) };
    }

    return { error: null };
  };

  const verifyInviteCode = async ({ email, code, inviteCode }: VerifyStudentInviteOtpCodeInput) => {
    authStore.reset();
    const { data, error } = await $urqlClient.mutation(verifyStudentInviteOtpCodeMutation, { input: { email, code, inviteCode } });

    if (error) {
      return { flow: null, error: getFirstErrorMessageFromCombinedErrors($i18n.t, error) };
    }

    if (!data?.core.org.school.verifyStudentInviteOtpCode?.flow) {
      return { flow: null, error: $i18n.t('errors.unknown') };
    }

    return { flow: data.core.org.school.verifyStudentInviteOtpCode.flow, error: null };
  };

  const logout = async (redirect: boolean = true, redirectPath: string | null = null) => {
    if (redirect) {
      authStore.isloggingOut = true;
    }

    authStore.reset();

    await $urqlClient.mutation(logoutMutation, {});

    $analytics.logout();

    if (redirect) {
      const defaultRedirectPath = isManageDomain ? '/' : '/login';
      await navigateTo({ path: redirectPath || defaultRedirectPath }, { replace: true });
      authStore.isloggingOut = false;
    }
  };

  const studentRoles = computed(() => {
    if (!user.value) {
      return [];
    }

    return user.value.studentRoles.map((r) => useFragment(studentRoleFragment, r));
  });

  const schoolStudentRoles = computed(() => {
    return studentRoles.value.filter((r) => r.contextType === RoleContextType.Org);
  });

  const globalStudentRole = computed(() => {
    return studentRoles.value.find((r) => r.contextType === RoleContextType.Global);
  });

  const schoolTeacherRoles = computed(() => {
    if (!user.value) {
      return [];
    }

    return user.value.teacherRoles.map((r) => useFragment(teacherRoleFragment, r));
  });

  const globalTeacherRole = computed(() => {
    return schoolTeacherRoles.value.find((r) => r.contextType === RoleContextType.Global);
  });

  watchEffect(() => {
    if (!authStore.currentSchoolId) {
      authStore.currentSchoolId = schoolTeacherRoles.value[0]?.orgId;
    }

    if (!schoolTeacherRoles.value.find((r) => r.orgId === authStore.currentSchoolId)) {
      authStore.currentSchoolId = schoolTeacherRoles.value[0]?.orgId;
    }
  });

  const switchToSchool = (schoolId: string) => {
    authStore.currentSchoolId = schoolId;
  };

  const globalRole = computed(() => {
    if (!user.value) {
      return null;
    }

    if (isManageDomain) {
      return globalTeacherRole.value;
    }

    return globalStudentRole.value;
  });

  const deleteSelf = async () => {
    const res = await useNuxtApp().$urqlClient.mutation(deleteSelfMutation, {});

    if (!res.data?.core.user.deleteSelf?.success) {
      return {
        success: false,
        error: res.error?.message || 'An unknown error occurred',
      };
    }

    await logout(true, '/account-deleted');

    return {
      success: true,
      error: null,
    };
  };

  return {
    user,
    fetchUser: authStore.fetchUser,
    isLoggedIn,
    reset: authStore.reset,
    requestLoginCode,
    triggerPostLoginRedirect,
    completeLoginProcess,
    verifyLoginCode,
    requestInviteCode,
    verifyInviteCode,
    logout,
    switchToSchool,
    studentRoles,
    schoolStudentRoles,
    schoolTeacherRoles,
    globalStudentRole,
    globalRole,
    globalTeacherRole,
    deleteSelf,
    currentSchoolId,
  };
};
