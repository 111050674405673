import revive_payload_client_jDeWEI8Cj3 from "/var/www/nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.2_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_234wknuxwkoznvyoehyedmv5ce/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_f5T55otJIj from "/var/www/nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.2_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_234wknuxwkoznvyoehyedmv5ce/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Vzn8sdvytL from "/var/www/nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.2_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_234wknuxwkoznvyoehyedmv5ce/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_4iTzLsLkj2 from "/var/www/nuxt/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.24.2_vite@5.4.10_@types+node@22.8.2_sass-embe_nuopjbg24ljgo2bts7wqpaysle/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import _03_seo_fZau0DgIwP from "/var/www/nuxt/plugins/03.seo.ts";
import payload_client_hZjGBv3uJU from "/var/www/nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.2_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_234wknuxwkoznvyoehyedmv5ce/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Ej8xoeIjki from "/var/www/nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.2_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_234wknuxwkoznvyoehyedmv5ce/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_YP36WSXXPH from "/var/www/nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.2_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_234wknuxwkoznvyoehyedmv5ce/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_ijfafZZZoC from "/var/www/nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.2_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_234wknuxwkoznvyoehyedmv5ce/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_11FD8S78Nu from "/var/www/nuxt/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.2_typescript@5.6.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/var/www/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_7anuyUGpBM from "/var/www/nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.2_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_234wknuxwkoznvyoehyedmv5ce/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_gJYqbIqOWV from "/var/www/nuxt/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.21_h3@1.13.0_magicast@0.3.5_rollup@4.24.2_vite@5.4.10_@types+node@22.8.2_v7wdk3b2k2rfrc3taimglcpflq/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaultsWaitI18n_55XRw1apOy from "/var/www/nuxt/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.21_h3@1.13.0_magicast@0.3.5_rollup@4.24.2_vite@5.4.10_@types+node@22.8.2_v7wdk3b2k2rfrc3taimglcpflq/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaultsWaitI18n.js";
import siteConfig_RLdJO6mnRy from "/var/www/nuxt/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.24.2_vite@5.4.10_@types+node@22.8.2_sass-e_6semotbjydjiyekvlmtc2qih5e/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_Lq2Zz81bBB from "/var/www/nuxt/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.24.2_vite@5.4.10_@types+node@22.8.2_sass-e_6semotbjydjiyekvlmtc2qih5e/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import switch_locale_path_ssr_TdLwAhfXDU from "/var/www/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.2_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_YfhTJdotje from "/var/www/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.2_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import _00_datadog_client_oBfMsBvh4D from "/var/www/nuxt/plugins/00.datadog.client.ts";
import _01_urql_b9cmdWz5Xy from "/var/www/nuxt/plugins/01.urql.ts";
import _02_loadConfig_RK9zG7A5iq from "/var/www/nuxt/plugins/02.loadConfig.ts";
import _04_auth_b3V54MNNLz from "/var/www/nuxt/plugins/04.auth.ts";
import _05_analytics_BKRvirLbPf from "/var/www/nuxt/plugins/05.analytics.ts";
import _06_appAssets_dOxy1bcLa6 from "/var/www/nuxt/plugins/06.appAssets.ts";
import gsap_client_YCLjR5XmPz from "/var/www/nuxt/plugins/gsap.client.ts";
export default [
  revive_payload_client_jDeWEI8Cj3,
  unhead_f5T55otJIj,
  router_Vzn8sdvytL,
  _0_siteConfig_4iTzLsLkj2,
  _03_seo_fZau0DgIwP,
  payload_client_hZjGBv3uJU,
  navigation_repaint_client_Ej8xoeIjki,
  check_outdated_build_client_YP36WSXXPH,
  chunk_reload_client_ijfafZZZoC,
  plugin_vue3_11FD8S78Nu,
  components_plugin_KR1HBZs4kY,
  prefetch_client_7anuyUGpBM,
  titles_gJYqbIqOWV,
  defaultsWaitI18n_55XRw1apOy,
  siteConfig_RLdJO6mnRy,
  inferSeoMetaPlugin_Lq2Zz81bBB,
  switch_locale_path_ssr_TdLwAhfXDU,
  i18n_YfhTJdotje,
  _00_datadog_client_oBfMsBvh4D,
  _01_urql_b9cmdWz5Xy,
  _02_loadConfig_RK9zG7A5iq,
  _04_auth_b3V54MNNLz,
  _05_analytics_BKRvirLbPf,
  _06_appAssets_dOxy1bcLa6,
  gsap_client_YCLjR5XmPz
]