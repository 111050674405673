<script setup lang="ts">
import type { NuxtError } from '#app';
import UICenteredContainer from './components/UI/UICenteredContainer.vue';

interface Props {
  error: NuxtError;
}
defineProps<Props>();

const { isLoggedIn } = useAuth();
const { isManageDomain } = useDomainConfig();

const layout = computed(() => {
  if (!isLoggedIn.value) {
    return resolveComponent('LayoutDefault');
  }
  if (isManageDomain) {
    return resolveComponent('LayoutManage');
  }

  return resolveComponent('LayoutPlan');
});

const exitErrorScreen = async () => {
  await clearError({ redirect: isLoggedIn.value ? '/dashboard' : '/' });
};
</script>

<template>
  <component :is="layout">
    <UICenteredContainer>
      <div class="flex flex-col items-center justify-center" :data-testid="`error-header-${error.statusCode}`">
        <UIHeading class="mb-5" size="h1" styleSize="h3">{{ error.message }}</UIHeading>
        <UIButton :text="isLoggedIn ? $t('nav.dashboard') : $t('nav.home')" @click="exitErrorScreen" />
      </div>
    </UICenteredContainer>
  </component>
</template>
