import { required, email, maxLength, helpers, url } from '@vuelidate/validators';

export const useValidation = () => {
  const MAX_STRING_LENGTH = 255;
  // Regular expression to match US 5-digit ZIP or 9-digit ZIP+4
  const usPostcodeRegex = /^[0-9]{5}(?:-[0-9]{4})?$/;

  const notUndefinedRule = (errorMessage: string) => helpers.withMessage(errorMessage, (v) => v !== undefined);

  // Custom HTTPS validator
  const httpsUrl = helpers.withMessage('The url must start with https://', (value: string) => {
    if (!value) {
      // Don't validate if the value is empty,
      // we already use required for that
      return true;
    }
    // Check if the URL starts with https://
    return value.startsWith('https://');
  });

  return {
    helpers,
    required: helpers.withMessage('This value is required.', required),
    email: helpers.withMessage('This email is invalid.', email),
    maxLength,
    maxStringLength: maxLength(MAX_STRING_LENGTH),
    url: helpers.withMessage('The url is not in correct format.', url),
    httpsUrl,
    postcode: helpers.withMessage('Please enter a valid US postcode.', (value: string) => {
      // If the value is empty, don't validate (use required if necessary)
      if (!value) {
        return true;
      }
      return usPostcodeRegex.test(value);
    }),
    dateLte: (maxDateVal: string, fieldName: string) =>
      helpers.withParams(
        { type: 'dateLte', value: maxDateVal },
        helpers.withMessage(`Must be before ${fieldName}`, (value: string) => {
          if (!maxDateVal) {
            return true;
          }

          return new Date(maxDateVal) >= new Date(value);
        }),
      ),
    notUndefinedRule,
  };
};
