import validate from "/var/www/nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.2_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_234wknuxwkoznvyoehyedmv5ce/node_modules/nuxt/dist/pages/runtime/validate.js";
import _00_45auth_45route_45protection_45global from "/var/www/nuxt/middleware/00.auth-route-protection.global.ts";
import _01_45autoload_45global from "/var/www/nuxt/middleware/01.autoload.global.ts";
import manifest_45route_45rule from "/var/www/nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.2_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_234wknuxwkoznvyoehyedmv5ce/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _00_45auth_45route_45protection_45global,
  _01_45autoload_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}