import { default as account_45deletedRZMGIcH3INMeta } from "/var/www/nuxt/pages/manage/account-deleted.vue?macro=true";
import { default as indexYRjReWtznUMeta } from "/var/www/nuxt/pages/manage/account/index.vue?macro=true";
import { default as indexZF77lnoTHrMeta } from "/var/www/nuxt/pages/manage/dashboard/index.vue?macro=true";
import { default as indexPp17kELPz4Meta } from "/var/www/nuxt/pages/manage/index.vue?macro=true";
import { default as indexLb61ogAL0BMeta } from "/var/www/nuxt/pages/manage/student-invites/index.vue?macro=true";
import { default as invitegA5jtpG6ziMeta } from "/var/www/nuxt/pages/manage/student-invites/invite.vue?macro=true";
import { default as _91student_role_id_93VzYcmGb95PMeta } from "/var/www/nuxt/pages/manage/students/[student_role_id].vue?macro=true";
import { default as terms_45conditionspDXnkAlzNMeta } from "/var/www/nuxt/pages/manage/terms-condition.vue?macro=true";
import { default as index65JcJrTFbdMeta } from "/var/www/nuxt/pages/manage/verify-email/index.vue?macro=true";
import { default as indexwXNe8CrZskMeta } from "/var/www/nuxt/pages/plan/academic-plan/index.vue?macro=true";
import { default as accept_45invitePf9BcPm1SjMeta } from "/var/www/nuxt/pages/plan/accept-invite.vue?macro=true";
import { default as account_45deletedLXUa00BkwkMeta } from "/var/www/nuxt/pages/plan/account-deleted.vue?macro=true";
import { default as index487lcM1dM3Meta } from "/var/www/nuxt/pages/plan/account/index.vue?macro=true";
import { default as dashboardJpOhOADSooMeta } from "/var/www/nuxt/pages/plan/dashboard.vue?macro=true";
import { default as indexxNfMJhmnnZMeta } from "/var/www/nuxt/pages/plan/education-pathways/index.vue?macro=true";
import { default as error_45test_45clientLyn3ULBtdEMeta } from "/var/www/nuxt/pages/plan/error-test-client.vue?macro=true";
import { default as error_45test_45ssrX8sBJJAQ1zMeta } from "/var/www/nuxt/pages/plan/error-test-ssr.vue?macro=true";
import { default as indexm2m0v1ZwvNMeta } from "/var/www/nuxt/pages/plan/index/index.vue?macro=true";
import { default as indexQhaow4CLuqMeta } from "/var/www/nuxt/pages/plan/institution-explore/index.vue?macro=true";
import { default as _91id_93tyXpBxhtpQMeta } from "/var/www/nuxt/pages/plan/job-explore/[id].vue?macro=true";
import { default as indexrpgSC621dsMeta } from "/var/www/nuxt/pages/plan/job-explore/index.vue?macro=true";
import { default as loginxyc02lB9RqMeta } from "/var/www/nuxt/pages/plan/login.vue?macro=true";
import { default as indexTHHTD4BXUUMeta } from "/var/www/nuxt/pages/plan/onboarding/index.vue?macro=true";
import { default as index4Mm5wru50lMeta } from "/var/www/nuxt/pages/plan/portfolio/index.vue?macro=true";
import { default as indexpW3KXFiwXXMeta } from "/var/www/nuxt/pages/plan/summary/index.vue?macro=true";
import { default as indexRowiVbma7uMeta } from "/var/www/nuxt/pages/plan/take-the-quiz/index.vue?macro=true";
import { default as terms_45conditionzhAjEP9BwEMeta } from "/var/www/nuxt/pages/plan/terms-condition.vue?macro=true";
import { default as indexJTJtqXFkMRMeta } from "/var/www/nuxt/pages/plan/verify-email/index.vue?macro=true";
import { default as component_45stubVrN1x5qaFsMeta } from "/var/www/nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.2_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_234wknuxwkoznvyoehyedmv5ce/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubVrN1x5qaFs } from "/var/www/nuxt/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.8.2_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_234wknuxwkoznvyoehyedmv5ce/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "manage-account-deleted",
    path: "/manage/account-deleted",
    meta: account_45deletedRZMGIcH3INMeta || {},
    component: () => import("/var/www/nuxt/pages/manage/account-deleted.vue")
  },
  {
    name: "manage-account",
    path: "/manage/account",
    component: () => import("/var/www/nuxt/pages/manage/account/index.vue")
  },
  {
    name: "manage-dashboard",
    path: "/manage/dashboard",
    component: () => import("/var/www/nuxt/pages/manage/dashboard/index.vue")
  },
  {
    name: "manage",
    path: "/manage",
    meta: indexPp17kELPz4Meta || {},
    component: () => import("/var/www/nuxt/pages/manage/index.vue")
  },
  {
    name: "manage-student-invites",
    path: "/manage/student-invites",
    component: () => import("/var/www/nuxt/pages/manage/student-invites/index.vue")
  },
  {
    name: "manage-student-invites-invite",
    path: "/manage/student-invites/invite",
    component: () => import("/var/www/nuxt/pages/manage/student-invites/invite.vue")
  },
  {
    name: "manage-students-student_role_id",
    path: "/manage/students/:student_role_id()",
    component: () => import("/var/www/nuxt/pages/manage/students/[student_role_id].vue")
  },
  {
    name: "manage-terms-condition",
    path: "/manage/terms-condition",
    meta: terms_45conditionspDXnkAlzNMeta || {},
    component: () => import("/var/www/nuxt/pages/manage/terms-condition.vue")
  },
  {
    name: "manage-verify-email",
    path: "/manage/verify-email",
    meta: index65JcJrTFbdMeta || {},
    component: () => import("/var/www/nuxt/pages/manage/verify-email/index.vue")
  },
  {
    name: "plan-academic-plan",
    path: "/plan/academic-plan",
    component: () => import("/var/www/nuxt/pages/plan/academic-plan/index.vue")
  },
  {
    name: "plan-accept-invite",
    path: "/plan/accept-invite",
    meta: accept_45invitePf9BcPm1SjMeta || {},
    component: () => import("/var/www/nuxt/pages/plan/accept-invite.vue")
  },
  {
    name: "plan-account-deleted",
    path: "/plan/account-deleted",
    meta: account_45deletedLXUa00BkwkMeta || {},
    component: () => import("/var/www/nuxt/pages/plan/account-deleted.vue")
  },
  {
    name: "plan-account",
    path: "/plan/account",
    component: () => import("/var/www/nuxt/pages/plan/account/index.vue")
  },
  {
    name: "plan-dashboard",
    path: "/plan/dashboard",
    component: () => import("/var/www/nuxt/pages/plan/dashboard.vue")
  },
  {
    name: "plan-education-pathways",
    path: "/plan/education-pathways",
    component: () => import("/var/www/nuxt/pages/plan/education-pathways/index.vue")
  },
  {
    name: "plan-error-test-client",
    path: "/plan/error-test-client",
    meta: error_45test_45clientLyn3ULBtdEMeta || {},
    component: () => import("/var/www/nuxt/pages/plan/error-test-client.vue")
  },
  {
    name: "plan-error-test-ssr",
    path: "/plan/error-test-ssr",
    meta: error_45test_45ssrX8sBJJAQ1zMeta || {},
    component: () => import("/var/www/nuxt/pages/plan/error-test-ssr.vue")
  },
  {
    name: "plan-index",
    path: "/plan",
    meta: indexm2m0v1ZwvNMeta || {},
    component: () => import("/var/www/nuxt/pages/plan/index/index.vue")
  },
  {
    name: "plan-institution-explore",
    path: "/plan/institution-explore",
    component: () => import("/var/www/nuxt/pages/plan/institution-explore/index.vue")
  },
  {
    name: "plan-job-explore-id",
    path: "/plan/job-explore/:id()",
    component: () => import("/var/www/nuxt/pages/plan/job-explore/[id].vue")
  },
  {
    name: "plan-job-explore",
    path: "/plan/job-explore",
    component: () => import("/var/www/nuxt/pages/plan/job-explore/index.vue")
  },
  {
    name: "plan-login",
    path: "/plan/login",
    meta: loginxyc02lB9RqMeta || {},
    component: () => import("/var/www/nuxt/pages/plan/login.vue")
  },
  {
    name: "plan-onboarding",
    path: "/plan/onboarding",
    component: () => import("/var/www/nuxt/pages/plan/onboarding/index.vue")
  },
  {
    name: "plan-portfolio",
    path: "/plan/portfolio",
    component: () => import("/var/www/nuxt/pages/plan/portfolio/index.vue")
  },
  {
    name: "plan-summary",
    path: "/plan/summary",
    component: () => import("/var/www/nuxt/pages/plan/summary/index.vue")
  },
  {
    name: "plan-take-the-quiz",
    path: "/plan/take-the-quiz",
    meta: indexRowiVbma7uMeta || {},
    component: () => import("/var/www/nuxt/pages/plan/take-the-quiz/index.vue")
  },
  {
    name: "plan-terms-condition",
    path: "/plan/terms-condition",
    meta: terms_45conditionzhAjEP9BwEMeta || {},
    component: () => import("/var/www/nuxt/pages/plan/terms-condition.vue")
  },
  {
    name: "plan-verify-email",
    path: "/plan/verify-email",
    component: () => import("/var/www/nuxt/pages/plan/verify-email/index.vue")
  },
  {
    name: component_45stubVrN1x5qaFsMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubVrN1x5qaFs
  },
  {
    name: component_45stubVrN1x5qaFsMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubVrN1x5qaFs
  }
]