<script lang="ts" setup></script>

<template>
  <div class="relative h-screen overflow-hidden md:flex-row">
    <UIGradientBackground position="top-right" intensity="darker" twGradientClasses="from-[#6100ff] to-[#00d5fc]" />
    <UIGradientBackground position="bottom-left" intensity="darker" twGradientClasses="from-[#6100ff] to-[#00d5fc]" />
    <div class="overflow-y-auto h-screen">
      <Suspense>
        <slot />
        <template #fallback>
          <UICenteredContainer>
            <UILoader size="lg" />
          </UICenteredContainer>
        </template>
      </Suspense>
    </div>
  </div>
</template>
