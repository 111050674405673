<script lang="ts" setup>
import type { NavItem } from '~/types/navigation';

interface Props {
  navItems: NavItem[];
  footerItems: NavItem[];
}

defineProps<Props>();

const { isSidebarOpen } = storeToRefs(useUiStore());
</script>

<template>
  <div class="relative flex flex-col h-screen overflow-hidden md:flex-row isolate">
    <UIGradientBackground position="top-right" twGradientClasses="from-primary-500/70 to-primary-500/70" />
    <UIGradientBackground position="top-left" twGradientClasses="from-primary-500/70 to-primary-500/70" />
    <UINavigationSideDesktop v-model:open="isSidebarOpen" :footerItems="footerItems" :navItems="navItems" class="hidden md:block rounded-r-xl">
      <template #logo>
        <NuxtLink aria-label="dashboard" to="/dashboard">
          <CoreClusterLogo variant="iconmarkLight" />
        </NuxtLink>
      </template>
    </UINavigationSideDesktop>
    <div class="w-full h-screen px-3 overflow-y-auto align-start md:px-6">
      <div class="container">
        <UINavigationTopMobile v-model:open="isSidebarOpen" :footerItems="footerItems" :navItems="navItems" class="block md:hidden" />
        <main id="main-content" class="px-4 py-4 my-5 sm:my-12">
          <slot name="top-content" />
          <Suspense>
            <slot />
            <template #fallback>
              <UICenteredContainer>
                <UILoader size="lg" />
              </UICenteredContainer>
            </template>
          </Suspense>
        </main>
      </div>
    </div>
    <slot name="extras" />
  </div>
</template>
